import Huskie from "./Img/Huskie.png";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <img src={Huskie} className="logo" alt="Huskie" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "5%",
          border: "2px solid grey", // Combined shorthand for border properties
          borderRadius: "10px",
          borderRadius: "10px",
          padding: "2%",
        }}
      >
        <p>
          Huskie Crypto Exchange is a secure, reliable and easy to use
          cryptocurrency exchange platform. Huskie Crypto Exchange is designed
          to be a secure, reliable and for web and mobile applications. It
          combines the Nox Cerberus authentication controller with custom
          trading engine, wallet services, web socket API, market matching
          engine and blockchain daemons. Production in progress...
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default App;
